import React from "react"

import { PricingDetailed } from "../components/index-sections/pricing/PricingDetailed";
import { SubpageLayout } from "../components/SubpageLayout";
import { Seo } from "../components/Seo";
import { SideElements } from '../components/SideElements/SideElements';

const PriceListPage = () => (
  <SubpageLayout
    title="Cennik"
  >
    <Seo
      title="Szczegółowy cennik"
      description="Szczegółowy cennik pakietów aplikacji FAKTURUJ.TO"
      keywords={["cennik", "szczegółowy"]}
    />
    <SideElements/>
    <PricingDetailed/>
  </SubpageLayout>
);

export default PriceListPage
